








































































































































































































































































































































































































































































































































































































@import '~@riophae/vue-treeselect/dist/vue-treeselect.css';
.remove-icon {
  cursor: pointer;
  margin: 0px 0px 0px 20px;
  transform: scale(1);
  translate: 0.2s;
  svg {
    width: 24px;
    height: 24px;
  }
  &:hover {
    translate: 0.2s;
    transform: scale(1.1);
  }
}

.video-frame-wrapper {
  position: relative;
  .video-frame-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
.selected-video {
  width: 100%;
  height: 300px;
}
.video-list {
  max-height: calc(100vh - 300px);
  overflow-y: auto;
}
.cat-check {
  max-height: 300px;
  overflow-y: auto;
}
.vid-list {
  position: relative;
  .vid-remove {
    position: absolute;
    bottom: 0;
    right: 0;
  }
}
